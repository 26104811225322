<template>
  <v-dialog
    v-model="isRefundModalOpen"
    width="70vw"
    height="800"
    persistent
    content-class="dialog-background"
  >
    <v-row justify="center">
      <v-col cols="6">
        <v-card>
          <v-toolbar
            class="py-2"
            flat
          >
            <v-toolbar-title>
              <h2 class="font-weight-regular text-h3">
                {{ $t("pos_n.refund_or_return") }}
              </h2>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              class="mr-0"
            >
              <v-icon @click="close()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                offset-sm="3"
              >
                <v-autocomplete
                  outlined
                  dense
                  v-model="selectedOrder"
                  :items="orders"
                  item-text="tracking_number"
                  item-value="id"
                  :label="$t('search')"
                  :search-input.sync="search_orders"
                  :loading="isLoadingOrders"
                  @change="onOrderSelect"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-list
              style="height: 40vh; overflow-y: scroll; overflow-x: hidden"
            >
              <v-list-item
                v-for="item in filteredProducts"
                :key="item.id"
              >
                <v-list-item-action>
                  <v-icon
                    color="red"
                    @click="remove(item)"
                    >mdi-close</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content class="py-0">
                  <div class="d-flex justify-space-between">
                    <v-list-item-title
                      class="text-h4"
                      style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ item.name }}
                    </v-list-item-title>
                    <v-btn
                      icon
                      outlined
                      color="primary"
                      @click="decreaseQte(item)"
                    >
                      {{ item.qte }}
                    </v-btn>
                  </div>
                  <v-list-item-subtitle>
                    <h6 class="text-h6">{{ item.price }} DZD</h6>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              @click="close()"
              color="indigo"
              text
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="primary"
              text
              @click="submitOrder"
            >
              {{ $t("pos_n.validate_return") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              outlined
              text
              @click="submitpartialRefund"
            >
              {{ $t("pos_n.validate_refund") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        v-if="refundedProducts?.length > 0"
      >
        <!-- Card content goes here -->
        <v-card
          class="mx-auto"
          max-width="600"
          outlined
        >
          <v-toolbar
            class="py-2"
            flat
          >
            <v-toolbar-title>
              <h2 class="font-weight-regular text-h3">
                {{ $t("pos_n.refund_or_return") }}
              </h2>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              class="mr-0"
            >
              <v-icon @click="close()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list style="height: 40vh; overflow-y: scroll; overflow-x: hidden">
            <v-list-item
              v-for="item in refundedProducts"
              :key="item.id"
            >
              <v-list-item-content class="py-0">
                <div class="d-flex justify-space-between">
                  <v-list-item-title
                    class="text-h4"
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </div>
                <v-list-item-subtitle>
                  <h5>
                    {{ item.refundQuantity }} units X
                    {{ item.price }}
                    <span
                      v-show="item?.promotion_id"
                      class="text-h6"
                      >{{
                        `( ${item?.promotion_name} (${item?.promotion_discount} % ) ) `
                      }}
                    </span>
                    =
                    {{ item.refundTotal.toFixed(2) }} DZD
                  </h5>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card
          class="mx-auto mt-2"
          max-width="600"
          outlined
        >
          <v-toolbar
            class="py-2"
            flat
          >
            <v-toolbar-title>
              <h2 class="font-weight-regular text-h3">total</h2>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <h2 class="text-h2">{{ total }} DZD</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <refund-a7
      ref="refundA7"
      :order="partialRefund"
    ></refund-a7>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Localbase from "localbase";
// import dbService from '@/store/services/localdb-service'
import { network } from "@/mixins/network";
import debounce from "lodash/debounce";
import Vue from "vue";

let db = new Localbase("db");
db.config.debug = false;
export default {
  mixins: [network],

  props: {
    isRefundModalOpen: Boolean,
    toggleRefundModal: Function,
  },
  async mounted() {
    await this.$store.dispatch("orders/autoComplete");
  },
  components: {
    refundA7: () => import("../../../pdf/pos/refundA7New.vue"),
  },
  computed: {
    ...mapGetters({
      isLoadingOrders: "orders/isLoadingOrders",
      orders: "orders/trackingNumbers",
    }),
    order: {
      get() {
        return this.$store.state.orders.refundOrder;
      },
      set(value) {
        this.$store.commit("orders/SET_REFUND_ORDER", value);
      },
    },
    filteredProducts() {
      return this.order?.products.filter((item) => item?.display !== false);
    },
    refundedProducts() {
      return this.order?.products.filter((item) => item?.refundQuantity > 0);
    },

    total() {
      return this.order?.products
        .reduce((acc, item) => acc + (item?.refundTotal ?? 0), 0)
        .toFixed(2);
    },
  },
  watch: {
    search_orders: debounce(function (search) {
      if (this.isLoadingOrders || !search) return;
      this.$store.dispatch("orders/autoComplete", {
        search,
      });
    }, 1000),
  },
  data() {
    return {
      partialRefund: {
        products: [],
        refundTotal: 0,
        created_at: "",
        tracking_number_ext: "",
      },
      selectedOrder: "",
      search_orders: "",
    };
  },
  methods: {
    async onOrderSelect(selectedItem) {
      await this.$store.dispatch("orders/getRefundOrder", selectedItem);
    },
    close() {
      this.order = { products: [] };
      this.toggleRefundModal();
    },
    async submitOrder() {
      try {
        if (!this.order || this.order.length == 0) {
          this.$store.dispatch("alerts/error", "Products are required");
          this.is_saving = false;
          return;
        }
        await this.$store.dispatch("orders/returnOrder", this.order.id);

        this.$store.dispatch(
          "alerts/success",
          this.$t("oreder_returned_successfully")
        );
        this.close();
      } catch (error) {
        this.$store.dispatch("alerts/error", error);
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.is_saving = false;
      }
    },
    async submitpartialRefund() {
      try {
        if (this.refundedProducts?.length > 0) {
          this.partialRefund.products = this.refundedProducts;
          this.partialRefund.refundTotal = this.total;
          let created_at = new Date();
          this.partialRefund.created_at = `${created_at.getDate()}/${
            created_at.getMonth() + 1
          }/${created_at.getFullYear()} à ${created_at.getHours()}:${created_at.getMinutes()}:${created_at.getSeconds()}`;
          this.partialRefund.tracking_number_ext =
            this.order.tracking_number_ext;
          setTimeout(() => {
            this.$refs.refundA7.generateReport();
          }, 1000);
          const refundOrder = {
            id: this.order.id,
            products: this.refundedProducts.map((item) => ({
              id: item.id,
              qte: item.refundQuantity,
            })),
            created_at: created_at,
          };
          const res = await this.refundProducts(refundOrder);
          if (res) {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.close();
          } else {
            this.$store.dispatch("alerts/error", this.$t("error"));
          }
        } else {
          this.$store.dispatch("alerts/error", "Products are required");
        }
      } catch (error) {
        this.$store.dispatch("alerts/error", error);
      }
    },
    async refundProducts(refundOrder) {
      if (!this.isOnline) {
        // await db
        //   .collection('orders')
        //   .doc(this.order.tracking_number_ext)
        //   .set(this.order)
        return;
      }
      try {
        await this.$store.dispatch("orders/refundProducts", refundOrder);
        return true;
      } catch (error) {
        if (error.code !== "ECONNABORTED") {
          // const data = {
          //   failed: 1,
          //   error:
          //     error?.response?.data?.errors ?? error?.response?.data?.message
          // }
          // dbService.updateOrderByTrackingNumber(order.tracking_number_ext, data)
          await this.$store.dispatch("orders/logErrorPos", refundOrder, {
            root: true,
          });
          // dbService.markOrderAsLogged(order.tracking_number_ext)
        } else throw new Error("An error occurred");
      }
    },
    remove(item) {
      item.refundQuantity = (item?.refundQuantity ?? 0) + item.qte;
      item.qte = 0;
      item.refundTotal = item.refundQuantity * item.price;
      Vue.set(item, "display", false);
    },
    decreaseQte(item) {
      if (item.qte === 1) {
        Vue.set(item, "display", false);
      }
      item.refundQuantity = (item?.refundQuantity ?? 0) + 1;
      item.qte--;
      const refundTotal = this.calculateItemRefundTotal(item);
      Vue.set(item, "refundTotal", refundTotal);
    },
    calculateItemRefundTotal(item) {
      let total = item.refundQuantity * item.price;
      if (item?.promotion_id) {
        const discountAmount =
          Math.round(total * parseFloat(item?.promotion_discount ?? 0)) / 100;
        item.reduction =
          item?.max_discount_amount > 0
            ? Math.min(discountAmount, item.max_discount_amount)
            : discountAmount;
        total -= item.reduction;
      }
      return total;
    },
  },
};
</script>
<style>
.dialog-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(10px);
}
</style>
